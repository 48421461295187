<template>
  <div>
    <main-nav :activeIndex="activeIndex" @checkRouter="checkRouter"></main-nav>
    <div class="about-banner solution-header-container">
      <div class="solution-header-text" style="margin-top: -80px">
        <h1 class="wow fadeInLeft">沈阳东信创智科技有限公司</h1>
        <p class="wow fadeInRight">DOTRUST TECHNOLOGIES</p>
      </div>
    </div>
    <anchor-area
      ref="companyIntro"
      anchorName="companyIntro"
      class="about-intro wow fadeInDown"
    >
      <template slot="content">
        <div class="container">
          <div class="normal-title">公司介绍</div>
          <p class="about-intro-content">
            沈阳东信创智科技有限公司（以下简称“东信创智”）2013年在沈阳成立，分别在北京、武汉、上海、重庆、广州等地开设了分公司或办事处。东信创智的核心团队拥有10多年深厚的汽车电子行业经验，业务范围覆盖国内90%以上的主要整车厂、研发机构和数量众多的汽车电子零部件供应商和服务商，并与多家国内或国际优秀的研发工具、研发平台、研发体系的提供方建立了牢固的合作伙伴关系。东信创智深耕于电子电气架构开发、车载总线通信与诊断测试、整车控制系统仿真测试平台、整车功能测试服务、嵌入式软件开发与集成服务等多个领域，致力于为客户提供安全可靠的研发工具和“本地化、快速化、定制化、产品化”的解决方案。东信创智不但在汽车电子传统领域的经验和能力一直走在行业的前列，如CAN/LIN/Ethernet开发与测试、控制系统功能测试、整车功能验证测试、控制系统HIL仿真平台、AUTOSAR软件开发与服务等，而且在新兴技术的探索方面也持续不断的提前投入研发并取得相应的成果，如ADAS智驾仿真、HMI测试验证、SOA架构开发、V2X测试验证、OTA测试验证、信息安全与功能安全等。
          </p>
          <p class="about-intro-content1">
            东信创智拥有多支“敢于挑战、乐于进取、善于拼搏、忠于客户”的经验丰富的技术服务团队，同众多合作伙伴一道整合全球优质的资源，为客户提供“高效、高质、高价值”的产品与服务。“尊重市场、回馈客户、取信员工、团结奋斗”是东信创智的核心价值观。我们在追求梦想的道路上，不忘“为国家和民族汽车工业贡献智慧”的初心，以精益求精、开拓进取的态度，坚持不懈的“帮助客户打造更卓越的产品”。
          </p>
        </div>
      </template>
    </anchor-area>
    <div class="about-intro1 wow fadeInLeft" id="about-culture">
      <div class="container">
        <div class="normal-title">企业文化</div>
        <div style="margin-top: 50px">
          <el-row :gutter="0">
            <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
              <div class="rectangle wow fadeInLeft">
                <img
                  src="../../assets/images/about-icon2.png"
                  alt="Image"
                  class="image"
                />
                <span class="left-text">使命</span>
                <div class="vertical-line"></div>
                <span class="right-textt">帮助客户打造卓越</span>
              </div>
              <div class="rectangle wow fadeInLeft">
                <img
                  src="../../assets/images/about-icon3.png"
                  alt="Image"
                  class="image"
                />
                <span class="left-text">愿景</span>
                <div class="vertical-line"></div>
                <span class="right-textt"
                  >初心——成为客户和员工最信任的公司<br /><br />
                  远方——用“智慧”连接每一个人的“梦想”</span
                >
              </div>
              <div class="rectangle wow fadeInLeft">
                <img
                  src="../../assets/images/about-icon.png"
                  alt="Image"
                  class="image"
                />
                <span class="left-text">价值观</span>
                <div class="vertical-line"></div>
                <span class="right-text1">尊重市场<br /><br />取信员工</span>
                <span class="right-text1">回馈客户<br /><br />团结奋斗</span>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <img
                class="rectangle-img wow fadeInRight"
                src="../../assets/images/about-companyy.png"
                alt=""
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <anchor-area
      ref="honor"
      anchorName="honor"
      class="about-honor wow fadeInRight"
    >
      <template slot="content">
        <div class="container">
          <div class="normal-title">荣誉资质</div>
          <div
              class="honor-container"
              @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
              @mouseleave="$refs.mySwiper.swiper.autoplay.start()"
          >
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item, index) in honorList" :key="index">
                <div class="honor-item">
                  <div class="honor-img">
                    <div class="border-inner">
                      <img :src="item.imgSrc" alt="" />
                    </div>
                  </div>
                  <div class="honor-title" :style="{'text-align': item.align || 'left'}">{{ item.title }}</div>
                  <!--                <div class="honor-type">著作权</div>-->
                </div>
              </swiper-slide>
              <!-- 根据情况引入控件 -->
              <!--            <div class="swiper-pagination" slot="pagination"></div>-->
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </template>
    </anchor-area>
    <div class="about-intro2 wow fadeInLeft" id="about-certification">
      <div class="container">
        <div class="normal-title">联盟资质</div>
        <el-row>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="img-position">
              <img src="../../assets/images/about-Open.png" alt="" />
            </div>
            <div class="title-position">Open Alliance—Adopter会员</div>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="img-position">
              <img src="../../assets/images/about-zhinengwanglian.png" alt="" />
            </div>
            <div class="title-position">智能网联汽车产业创新联盟—会员单位</div>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="img-position">
              <img src="../../assets/images/about-zhongguo.png" alt="" />
            </div>
            <div class="title-position">中国汽车工程学会—会员单位</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <anchor-area
      ref="partner"
      anchorName="partner"
      class="about-intro2 about-partner wow fadeInRight"
    >
      <template slot="content">
        <div class="container">
          <div class="normal-title">合作伙伴</div>
          <el-row>
            <el-col :span="24">
              <div class="news-block-partner">
                <img src="../../assets/images/about-companyFr.png" alt="" />
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </anchor-area>
    <div class="about-intro4 wow fadeInLeft" id="about-customer">
      <div class="container">
        <div class="normal-title">重点客户</div>
        <article class="news-block">
          <img src="../../assets/images/about-customer.png" alt="" />
        </article>
      </div>
    </div>
    <bz-info />
    <main-footer @checkRouter="checkRouter"></main-footer>
  </div>
</template>
<script>
import mainNav from "@/components/MainNav";
import mainFooter from "@/components/MainFooter";
import bzInfo from "@/components/BzInfo";
import "@/style/contactUs.less";
import AnchorArea from "../../components/AnchorArea";
export default {
  components: {
    AnchorArea,
    mainNav,
    mainFooter,
    bzInfo,
  },
  data() {
    return {
      activeIndex: "5",
      swiperOption: {
        spaceBetween: 10, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于1100
          1100: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false, // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: ".swiper-button-prev", // 左侧按钮
          nextEl: ".swiper-button-next", // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true, // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      honorList: [
        {
          imgSrc: require("../../assets/images/honor-list/book-001.jpg"),
          title:
            "BJZ211-066-发明专利证书 - 2021107863026 - 一种基于VTS的VIL测试平台",
          type: 3,
        },
        {
          imgSrc: require("../../assets/images/honor-list/book-002.jpg"),
          title:
            "BJZ212-106-实用新型专利证书 - 2021221444690 - 一种基于SBC的CANFD、LIN网关",
          type: 3,
        },
        {
          imgSrc: require("../../assets/images/honor-list/book-003.png"),
          title: "辽宁省“专精特新”中小企业",
          align: 'center',
          type: 3,
        },
        {
          imgSrc: require("../../assets/images/honor-list/book-004.png"),
          title: "高新技术企业",
          align: 'center',
          type: 3,
        },
        {
          imgSrc: require("../../assets/images/honor-list/book-005.jpg"),
          title: "软件著作权若干",
          align: 'center',
          type: 3,
        },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-006.jpg"),
        //   title: "DoCARE Upper Tester以太网协议栈测试软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-007.jpg"),
        //   title: "DTech DoIP诊断协议测试软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-008.jpg"),
        //   title: "DTech ETH刷写测试软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-009.jpg"),
        //   title: "DBCover模板转换软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-010.jpg"),
        //   title: "DTech ETH通信配置测试软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-011.jpg"),
        //   title: "DTtech ORDGateWay CD Software  V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-012.jpg"),
        //   title: "DTtech SOMEIP CD for NIVS 软件 V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-013.jpg"),
        //   title: "DTtech StormDBConv_Com东信创智通信数据库转换工具软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-014.jpg"),
        //   title: "DTtech StormDBConv_Diag东信创智诊断数据库转换工具软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-015.jpg"),
        //   title: "DTtech StormDBConv_Diag东信创智诊断数据库转换工具软件V2.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-016.jpg"),
        //   title: "DTtech StormDXP东信创智数据交换平台",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-017.jpg"),
        //   title: "DTtech StormETM东信创智电子电气集成测试管理系统",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-018.jpg"),
        //   title: "DTtech StormEye Server东信创智测试执行管理软件V2.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-019.jpg"),
        //   title: "DTtech StormEye Server东信创智测试执行管理系统V3.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-020.jpg"),
        //   title: "DTtech StormEye Single东信创智测试执行管理工具软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-021.jpg"),
        //   title: "DTtech StormEyeSingle测试执行管理软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-022.jpg"),
        //   title: "DTtech StormIOConv东信创智IO模型转换工具软件V2.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-023.jpg"),
        //   title: "DTtech StormIOConv东信创智IO模型转换工具软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-024.jpg"),
        //   title: "DTtech StormMB东信创智消息代理平台",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-025.jpg"),
        //   title: "DTtech StormPM东信创智项目交付管理系统",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-026.jpg"),
        //   title: "DTtech StormScenaGen东信创智场景泛化工具软件",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-027.jpg"),
        //   title: "DTtech StormScena场景分析软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-028.jpg"),
        //   title: "DTtech StormScena东信创智场景管理分析系统",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-029.jpg"),
        //   title: "DTtech StormScena东信创智场景管理分析系统V2.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-030.jpg"),
        //   title: "DTtech StormSimScena东信创智仿真场景库管理系统",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-031.jpg"),
        //   title: "DTtech VTD-NI CD Software V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-032.jpg"),
        //   title: "DTtech通用设备程控软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-033.jpg"),
        //   title: "PREEvision辅助建模二次开发软件V1",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-034.jpg"),
        //   title: "计算机软件著作权登记证书2017SR313348",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-035.jpg"),
        //   title: "计算机软件著作权登记证书2017SR313348",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-036.jpg"),
        //   title: "计算机软件著作权登记证书2017SR313365",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-037.jpg"),
        //   title: "计算机软件著作权登记证书2017SR313446",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-038.jpg"),
        //   title: "计算机软件著作权登记证书2017SR361691",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-039.jpg"),
        //   title: "计算机软件著作权登记证书2017SR361693",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-040.jpg"),
        //   title: "计算机软件著作权登记证书2017SR532988",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-041.jpg"),
        //   title: "计算机软件著作权登记证书2017SR533394",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-042.jpg"),
        //   title: "计算机软件著作权登记证书2017SR540870",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-043.jpg"),
        //   title: "计算机软件著作权登记证书2017SR544083",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-044.jpg"),
        //   title: "计算机软件著作权登记证书2018SR060240-常用",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-045.jpg"),
        //   title: "计算机软件著作权登记证书2018SR060649",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-046.jpg"),
        //   title: "计算机软件著作权登记证书2018SR061672",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-047.jpg"),
        //   title: "计算机软件著作权登记证书2018SR061677",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-048.jpg"),
        //   title: "计算机软件著作权登记证书2018SR061695",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-049.jpg"),
        //   title: "计算机软件著作权登记证书2018SR061707",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-050.jpg"),
        //   title: "软著证书-DTtech StormUTP东信创智统一测试管理平台V2.0",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-051.jpg"),
        //   title: "软著证书-stormEye Server系统-东信",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-052.jpg"),
        //   title: "证书",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-053.jpg"),
        //   title: "20220826092703405_0016",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-054.jpg"),
        //   title: "20220826092703405_0017",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-055.jpg"),
        //   title: "20220826092703405_0019",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-056.jpg"),
        //   title: "20220826092703405_0020",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-057.jpg"),
        //   title: "CARE IP",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-058.jpg"),
        //   title: "CARE PARSER",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-059.jpg"),
        //   title: "CARE SOMEIP",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-060.jpg"),
        //   title: "CARE UDP",
        //   type: 3,
        // },
        // {
        //   imgSrc: require("../../assets/images/honor-list/book-061.jpg"),
        //   title: "CARE UPPER",
        //   type: 3,
        // },
      ],
    };
  },
  mounted() {
    document.title = "走进东信 - 东信创智";
    new this.$wow.WOW().init();
    this.checkRouter(this.$route.hash);
  },
  methods: {
    // 检查路由后滚动至某标题
    checkRouter(routeHash) {
      switch (routeHash) {
        case "#companyIntro":
          this.$refs.companyIntro.go();
          break;
        case "#honor":
          this.$refs.honor.go();
          break;
        case "#partner":
          this.$refs.partner.go();
          break;
        default:
          break;
      }
      this.$router.push({ hash: routeHash });
    },
  },
};
</script>

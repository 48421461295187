<template>
  <div class="anchor-area" :ref="anchorName">
    <slot name="content">锚点区域</slot>
  </div>
</template>

<script>
export default {
  name: "AnchorArea",
  props: {
    // 锚点名称
    anchorName: {
      type: String,
      default: "anchorArea",
    },
    // 滚动持续时间
    duration: {
      type: Number,
      default: 800,
    },
  },
  methods: {
    // （主要）滚动至当前位置
    go() {
      var dom = this.$refs[this.anchorName];
      scrollTo(dom.offsetTop, this.duration);
    },
  },
};
// 以下直接引入的scrollTo方法
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
var requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();
function move(amount) {
  document.documentElement.scrollTop = amount;
  document.body.parentNode.scrollTop = amount;
  document.body.scrollTop = amount;
}
function position() {
  return (
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop
  );
}
export function scrollTo(to, duration, callback) {
  const start = position();
  const change = to - start;
  const increment = 20;
  let currentTime = 0;
  duration = duration <= 100 ? 100 : duration;
  var animateScroll = function () {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      if (callback && typeof callback === "function") {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
}
</script>
